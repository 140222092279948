<template>
  <div>
    <!-- <h2>首页</h2> -->
    <h2>
      欢迎来到麦麦麦造的小站✨
      <br />
    </h2>
    <p style="font-size:15px;color:#999;margin-left:10px">你可以先体验这里的在线工具👇</p>
    <div class="tools" style="margin-top:1rem">
      <h3>开发工具</h3>
      <a-row type="flex" justify="space-between" :gutter="[32, { xs: 8, sm: 8, md: 16, lg: 32 }]">
        <a-col :xs="24" :sm="12" :md="12" :lg="6">
          <a-card class="card">
            <div class="card-title-wrapper">
              <router-link to="HttpRaw">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-HTTP" />
                </svg>
                <p class="card-title">HTTP Raw转Python代码</p>
              </router-link>
              <p class="card-label">
                <a-tag color="blue">开发</a-tag>
                <a-tag color="cyan">HTTP</a-tag>
              </p>
            </div>
            <a-divider />
            <div class="card-description">将Http raw格式的信息快速转换为Python3下的Requests的代码</div>
          </a-card>
        </a-col>

        <a-col :xs="24" :sm="12" :md="12" :lg="6">
          <a-card class="card">
            <div class="card-title-wrapper">
              <router-link to="FiddlerData">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-Dataanalysis" />
                </svg>
                <p class="card-title">Data转Dict</p>
              </router-link>
              <p class="card-label">
                <a-tag color="blue">开发</a-tag>
                <a-tag color="green">Fiddler</a-tag>
              </p>
            </div>
            <a-divider />
            <div class="card-description">将Fiddler里的FormData项快速的转换为Python里的Dict格式</div>
          </a-card>
        </a-col>

        <a-col :xs="24" :sm="12" :md="12" :lg="6">
          <a-card class="card">
            <div class="card-title-wrapper">
              <router-link to="FiddlerHeader">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-agent" />
                </svg>
                <p class="card-title">Header转Dict</p>
              </router-link>
              <p class="card-label">
                <!-- <a href="https://www.baidu.com" style="float:left;"></a> -->
                <a-tag color="blue">开发</a-tag>

                <a-tag color="green">Fiddler</a-tag>
              </p>
            </div>
            <a-divider />
            <div class="card-description">将Fiddler里的Header项快速的转换为Python里的Dict格式</div>
          </a-card>
        </a-col>

        <a-col :xs="24" :sm="12" :md="12" :lg="6">
          <a-card hoverable class="card">
            <div class="card-title-wrapper">
              <router-link to="FiddlerCookie">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-cookie" />
                </svg>
                <p class="card-title">Cookie转Dict</p>
              </router-link>
              <p class="card-label">
                <!-- <a href="https://www.baidu.com" style="float:left;"></a> -->
                <a-tag color="blue">开发</a-tag>

                <a-tag color="green">Fiddler</a-tag>
              </p>
            </div>
            <a-divider />
            <div class="card-description">将Fiddler里的Cookie项快速的转换为Python里的Dict格式</div>
          </a-card>
        </a-col>

        <a-col :xs="24" :sm="12" :md="12" :lg="6">
          <a-card hoverable class="card">
            <div class="card-title-wrapper">
              <router-link to="CurlToCode">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-curl" />
                </svg>
                <p class="card-title">cURL转原生代码</p>
              </router-link>
              <p class="card-label">
                <!-- <a href="https://www.baidu.com" style="float:left;"></a> -->
                <a-tag color="blue">开发</a-tag>

                <a-tag color="orange">cURL</a-tag>
              </p>
            </div>
            <a-divider />
            <div class="card-description">将cURL的命令转换成Python、Go、Node.js、Dart等语言的代码</div>
          </a-card>
        </a-col>


      </a-row>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

import 'ant-design-vue/dist/antd.css'
import { Card, Tag, Divider } from 'ant-design-vue'
Vue.use(Card)
Vue.use(Tag)
Vue.use(Divider)

export default {
  metaInfo: {
    title: '首页-麦麦麦造的小站', // set a title
    meta: [
      {
        // set meta
        name: 'keyWords',
        content:
          'Fiddler,Fiddler data,Fiddler Cookie,数据采集工具,爬虫开发工具,Http raw'
      },
      {
        // set meta
        name: 'description',
        content:
          '提供一些开发类的工具，可以帮你将抓到的包信息快速的转换为python的爬虫代码，助力开发！'
      }
    ]
  }
}
</script>

<style >
/* >>>>>  覆盖官方样式 */
.ant-card-body {
  padding: 10px;
}
.ant-divider-horizontal {
  margin: 10px 0 5px 0;
}
/* <<<<< 覆盖官方样式 */

.card {
  /* margin: 8px; */
  font-size: 16px;
  border-radius: 5px;
}
/* .card:hover {
  background-color: rgba(0, 0, 0, 0.05);
} */

.icon {
  width: 3em;
  height: 3em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.card-title-wrapper .icon {
  float: left;
  margin-right: 15px;
}
.card-title-wrapper .card-title {
  color: #333;
  font-weight: 600;
}
.card-title-wrapper p {
  margin: 0;
  padding: 0;
}
.card-description {
  font-size: 14px;
  width: 92%;
  color: #999;
  margin: 0 auto;
}
</style>