<template>
  <div>
    <h2>Fiddler Data 转 dict</h2>
    <a-row>
      <a-col :span="11">
        <a-textarea placeholder="请将FiddlerData值粘贴进来" style="height:500px;" v-model="rawData" />
      </a-col>

      <a-col :span="2" style="text-align:center">
        <a-button v-on:click="transClick">转换</a-button>
      </a-col>

      <a-col :span="11">
        <ToolRightWrapper :transData="transData" />

        <!-- <a-textarea placeholder="转换后的结果" style="height:500px;" v-model="transData" /> -->
      </a-col>
    </a-row>

    <div class="content">
      <h2>使用方法</h2>
      <a-row>
        <ol class="use-direction">
          <a-col :span="8">
            <li>复制 Fiddler 里 WebForms 的内容</li>

            <div>
              <p style="margin:0.1rem;font-size:15px">
                <b>Fiddler:</b>
              </p>
              <img src="https://images.alisen39.com/20200526002617.png" alt />


            </div>
          </a-col>

          <a-col :span="15" :offset="1">
            <li>将其粘贴至本页面左边窗口 ，点击"转换"按钮即可得到结果</li>
            <img src="https://images.alisen39.com/20200526002710.png" alt />
          </a-col>
        </ol>
      </a-row>
    </div>
  </div>
</template>

<script>
const axios = require('axios')
import ToolRightWrapper from '../components/ToolRightWrapper.vue'

export default {
  name: 'FiddlerData',
  components: {
    ToolRightWrapper
  },
  metaInfo: {
    title: 'Fiddler Data转requests代码 | 麦麦麦造的小站', // set a title
    meta: [
      {
        // set meta
        name: 'keyWords',
        content:
          'Fiddler,Fiddler Data,Fiddler Cookie,数据采集工具,爬虫开发工具,Http raw,快速爬虫开发'
      },
      {
        name: 'description',
        content: '将Fiddler里的FormData项快速的转换为Python里的Dict格式'
      }
    ]
  },

  data() {
    return {
      rawData: '',
      transData: ''
    }
  },
  methods: {
    transClick() {
      const _this = this
      if (this.$data.rawData.replace(' ', '').length <= 0) {
        this.$message.error('还没有输入参数哦！')
        return
      }

      axios({
        url: '/tools/fiddler_data/',
        method: 'post',
        data: {
          data: this.$data.rawData
        }
      })
        .then(function(response) {
          if (response.data['code'] != 200) {
            _this.$message.warning(response.data['msg'])
            return
          }
          _this.$data.transData = response.data['data'].replace(/',\s/g, "',\n")
        })
        .catch(function(error) {
          _this.$message.error(error.message)
          // console.log(error)
        })
    }
  }
}
</script>
<style >
@import url('./tools.css');
</style>