<template>
  <a-layout-header style="padding:0;">
    <div class="logo">
      <router-link to="/">
        <img src="../assets/logo.png" style="height:45px;" />
      </router-link>
    </div>
    <a-menu
      v-model="current"
      mode="horizontal"
      style="padding:0 5%;"
      :style="{width:'100%', lineHeight: '64px',textAlign: 'left'}"
    >
      <!-- <a-menu-item key="article">📚博客</a-menu-item> -->
      <a-sub-menu key="tools">
        <span slot="title" class="submenu-title-wrapper">
          <a-icon type="setting" />在线工具
          <!-- <router-link to="tools">
            <a-icon type="setting" />在线工具
          </router-link>-->
        </span>

        <a-menu-item-group title="开发工具">
          <a-menu-item key="tools:1">
            <router-link :to="{name:'HttpRaw'}">
              <!-- <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-HTTP" />
              </svg>-->
              HTTP Raw 转 Python代码
            </router-link>
          </a-menu-item>
          <a-menu-item key="tools:2">
            <router-link :to="{name:'FiddlerData'}">Data转Dict</router-link>
          </a-menu-item>
          <a-menu-item key="tools:3">
            <router-link :to="{name:'FiddlerHeader'}">Header转Dict</router-link>
          </a-menu-item>
          <a-menu-item key="tools:4">
            <router-link :to="{name:'FiddlerCookie'}">Cookie转Dict</router-link>
          </a-menu-item>
          <a-menu-item key="tools:5">
            <router-link :to="{name:'CurlToCode'}">cURL转原生代码</router-link>
          </a-menu-item>
        </a-menu-item-group>
        <!-- <a-menu-item-group title="图像识别">
          <a-menu-item key="tools:5">
            <router-link to="OCRText">文字识别</router-link>
          </a-menu-item>
        </a-menu-item-group>-->
      </a-sub-menu>
    </a-menu>
  </a-layout-header>
</template>

<script>
export default {
  name: 'HeaderNav',
  props: { msg: String },
  data() {
    return {
      current: ['tools']
    }
  }
}
</script>

<style>
.logo {
  width: 110px;
  /* height: 31px; */
  background: rgba(255, 255, 255, 0.2);
  /* margin: 16px 24px 16px 0; */
  float: left;
  margin-left: 7%;
}
.icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>