<template>
  <div class="right-wrapper" v-highlightjs="transData">
    <p class="tools-group">
      <a-button class="btn-cp" title="点击复制" icon="copy" size="small" @click="cpTransCode"></a-button>
    </p>
    <textarea id="cpBack"></textarea>
    <pre id="tansCode"><code class="python"></code></pre>
  </div>
</template>

<script>
export default {  
  name: 'ToolRightWrapper',
  props: {
    transData: {
      type: String
    }
  },
  data: function() {
    return {}
  },
  methods: {
    cpTransCode: function() {
      var text = document.getElementById('tansCode').innerText
      var cp_back = document.getElementById('cpBack')
      cp_back.value = text
      cp_back.select() //选中文本
      document.execCommand('copy')
      this.$message.success("复制成功")
    }
  }
}
</script>

<style lang="stylus" rel="stylesheet/stylus">
@import '~highlight.js/styles/tomorrow.css';

code {
  margin-left: -0.5px !important;
  word-wrap: break-world !important;
  white-space: pre-wrap !important;
}

.right-wrapper {
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  min-height: 500px;
}

.tools-group {
  margin: 0;
  padding: 0.25rem 0.5rem;
}

.btn-cp {
  float: right;
  background:none;
  border:none;
  box-shadow :none;
  
}

#cpBack {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -10;
}
</style>