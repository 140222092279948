<template>
  <a-layout-footer id="footer">
    <p>
      <span style="margin:0 5px">麦麦麦造的小站</span>
      <span>
        <a style="color:#aaa" href="mailto://alisen39@163.com">联系我</a>
      </span>
    </p>
    <!-- <p>
      备案
      <a href="">beian</a>
    </p> -->
  </a-layout-footer>
</template>

<script>
export default {
  name: 'FooterNav'
}
</script>

<style >
#footer {
  background-color: #454545;
  color: #aaa;
  text-align: center;
  font-size: 12px;
}
</style>