import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from 'axios'

import Index from '../views/Index.vue'
import FiddlerData from '../views/FinddlerData.vue'

Vue.use(VueRouter)
// console.log(process.env)
const routes = [{
    path: '/',
    name: 'Index',
    component: Index,

  },
  {
    path: '/Tools',
    name: 'Tools',
    component: () => import('../views/Tools.vue'),

  },
  {
    path: '/FiddlerData',
    name: 'FiddlerData',
    component:  FiddlerData,

  },
  {
    path: '/FiddlerHeader',
    name: 'FiddlerHeader',
    // component: FiddlerHeader,
    component: () => import('../views/FiddlerHeader.vue'),

  },
  {
    path: '/FiddlerCookie',
    name: 'FiddlerCookie',
    component: () => import('../views/FiddlerCookie.vue'),

  },
  {
    path: '/HttpRaw',
    name: 'HttpRaw',
    component: () => import('../views/HttpRaw.vue'),

  },
  {
    path: '/CurlToCode',
    name: 'CurlToCode',
    component: () => import('../views/CurlToCode.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  // window.document.title = to.meta.title;
  // console.log(to,from)


  axios({
    url: '/tools/history/',
    method: 'post',
    data: {
      'from': from.fullPath,
      'visit': to.fullPath
    }
  })

  next()
})
export default router