import Vue from 'vue'
import App from './App.vue'
import 'ant-design-vue/dist/antd.css';
import {
  Button,
  Layout,
  Menu,
  Input,
  Row,
  Col,
  Icon
} from 'ant-design-vue'
import router from './router'
import MetaInfo from 'vue-meta-info'
import axios from 'axios'
import './plugins/element.js'
import Message from 'ant-design-vue'
Vue.use(Message)
import VueHighlightJS from 'vue-highlightjs'
Vue.use(VueHighlightJS)


Vue.config.productionTip = false


Vue.use(Button)
Vue.use(Layout)
Vue.use(Menu)
Vue.use(Input)
Vue.use(Row)
Vue.use(Col)
Vue.use(Icon)


Vue.use(MetaInfo)
Vue.prototype.$ajax = axios

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.transformRequest = [function (data) {
  let ret = ''
  for (let it in data) {
    ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
  }
  return ret
}]


new Vue({

  router,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')