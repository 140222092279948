<template>
  <div id="app">
    <a-layout style="min-height:100vh;">
      <HeaderNav />

      <a-layout-content style="padding: 30px 4%;">
        <div :style="{ background: '#fff', padding: '24px', minHeight: 70+'vh' }" class="layout-content">
          <router-view />
        </div>
      </a-layout-content>
      <FooterNav />
    </a-layout>
  </div>
</template>

<script>
import HeaderNav from './components/HeaderNav.vue'
import FooterNav from './components/FooterNav.vue'
// import {Button,Layout} from 'ant-design-vue'
export default {
  name: 'App',
  components: {
    HeaderNav,
    FooterNav
  },
  data() {
    return {
      // load:false
      // contentMinHeight:document.body.clientHeight - 66 - 24-30-24-60
    }
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  height: auto;
}
.layout-content{
  border-radius: 5px;

}
</style>
